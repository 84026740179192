.container{
  flex: 1;
  height: 100%;
}

.card{
  display: flex;
  flex-direction: column;
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global{
    .ant-card-head{
      border-bottom: 1px solid #636262;
    }
    .ant-card-body{
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}
.row{
  flex: 1;
  height: 100%;
}
.btnsRow{
  height: 100%;
}

.title{
  text-align: left;
  font: normal normal 600 16px/24px Sarabun;
  letter-spacing: 0px;
  color: #636262;
}
.subtitle{
  font: normal normal normal 14px/18px Sarabun;
  letter-spacing: 0;
  color: #636262;
}

.switchItem{
  margin: 0!important;
  :global{
    .ant-switch-checked {
      background: #D9322E!important;
    }
  }
}

.dividerMain{
  margin-top: 20px!important;
  margin-bottom: 20px!important;
  border-top: 1px solid #636262;
}
.timeContainer{
  padding-left: 24px;
  margin-top: 13px;
}

.deleteIcon{
  cursor: pointer;
  &:hover{
    color: #ff0000;
  }
}

.addMore{
  margin-top: 12px;
  font: normal normal medium 14px/24px Sarabun;
  letter-spacing: 0;
  color: #636262;
  cursor: pointer;
}

.daysContainer{
  margin-top: 20px;
}

.times{
  &:not(:last-child){
    margin-bottom: 16px;
  }
}

.textArea{
  margin-top: 21px;
  margin-bottom: 0!important;
}