.modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.title {
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #262626;
}

.description {
    margin-top: 12px;
    margin-bottom: 26px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #777777;
}

.actionIcon {
    width: 45px;
    height: 45px;
    border-radius: 8px;
    background-color: #e8c199;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    svg{
        height: 24px;
        width: 24px;
    }
}
