.upload-images-left-side {
  width: 264px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;
  .ant-image {
    margin-bottom: 8px;
    &:nth-child(5),
    &:last-child {
      margin-bottom: 0;
    }
    img{
      object-fit: contain;
    }
  }
}
.upload-images-left-side-for-admin{
  margin-right: 0;
}
.upload-images-modal {
  .upload-images-modal-content {
    min-height: 402px;
    padding-top: 24px;
    display: flex;
    & > span {
      flex: 1;
      .upload-btn {
        margin-top: 22px;
        background-color: #2937b8;
        border-radius: 6px;
        border: none;
      }
    }
  }
  .upload-images-modal-content-superAdmin{
    justify-content: center;
  }
}
.cancel-text {
  text-align: end;
  padding-right: 25px;
  //margin-top: -63px;
}
.default-image {
  width: 42px;
  height: 42px;
  border: 1px solid #ced5e2;
  position: relative;
  z-index: 1;
  border-radius: 2px;
  cursor: pointer;
  .ant-image {
    img{
      object-fit: contain;
    }
  }
  &_disabled {
    cursor: unset;
    pointer-events: none;
  }
  &.has-more-images {
    &:after {
      z-index: 1;
      content: '';
      position: absolute;
      width: 7px;
      height: 38px;
      border-radius: 2px;
      border: 1px solid #ced5e2;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      top: 1px;
      right: -8px;
    }
    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      width: 5px;
      height: 36px;
      border-radius: 2px;
      border: 1px solid #ced5e2;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      top: 2px;
      right: -13px;
    }
  }
}

.disabled-cursor-item {
  cursor: not-allowed;
}

.image-demo {
  margin-bottom: 8px;
  z-index: 1111;
  width: 128px;
  position: relative;
  .image-options {
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 128px;
    .svg-icon {
      background-color: rgba(0,0,0,0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      margin-top: 4px;
      margin-left: 4px;

      border-radius: 2px;
      width: 24px;
      height: 24px;

      &:hover {
        background: rgba(206, 213, 226, 0.4);
      }
    }
  }
  .download-options {
    position: absolute;
    right: 10px;
    top: 0;
    width: 24px;
    height: 60px;
    .svg-icon {
      background: rgba(206, 213, 226, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      margin-top: 4px;
      margin-left: 4px;

      border-radius: 2px;
      width: 24px;
      height: 24px;

      &:hover {
        background: rgba(206, 213, 226, 0.4);
      }
    }
  }
  .set-default-container {
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 128px;
    height: 32px;
    border-radius: 0 0 4px 4px;
    padding: 8px;
    .ant-checkbox-wrapper {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: center;
      height: 16px;
      .ant-checkbox {
        top: 0;
      }
      .ant-checkbox + span {
        padding-left: 10px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1db65e;
        border-color: #1db65e;
      }
    }
    &.is-default {
      background: rgba(38, 38, 38, 0.9);
    }
  }
}
.img-upload-lef-icon {
  margin-bottom: 8px;
  border: none !important;
  .ant-upload {
    padding: 0 0 !important;
  }
}

