.barChange{
  position: relative;
  display: flex!important;
}

.barChangeLine{
  position: absolute;
  height: 400px;
  width: 100%;
}

.card{
  border: 1px #D5D5D5 solid;
  height: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}