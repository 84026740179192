.container{
  flex: 1;
  height: 100%;
}

.card{
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global{
    .ant-card-head{
      border-bottom: 1px solid #636262;
    }
  }
}

.cardSection{
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #636262;
}

.select{
  min-width: 160px;
}