.react-calendar {
  border: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: 900;
  &:hover {
    background: none !important;
  }
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  background-color: #faac18 !important;
  min-width: 16px !important;
  width: 22px !important;
  height: 22px !important;
  display: flex;
  margin-left: 10px;
  padding-left: 8px;
  font-weight: 900;
  font-size: 20px;
  color: white;
  position: relative;
  top: 30px;
  &:hover {
    background: #faac18 !important;
  }
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  background-color: #faac18 !important;
  min-width: 16px !important;
  width: 22px !important;
  height: 22px !important;
  display: flex;
  margin-left: 10px;
  margin-right: 14px;
  padding-left: 8px;
  font-weight: 900;
  font-size: 20px;
  color: white;
  position: relative;
  top: 30px;
  &:hover {
    background: #faac18 !important;
  }
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  position: relative;
  top: 20px;
}

.react-calendar__month-view__weekdays__weekday {
  margin-top: 17px;
}

.react-calendar__month-view__days {
  margin-top: 24px;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  background-color: #faac18 !important;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background-color: #faac18 !important;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  background: none;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
  font-weight: 800;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: white;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  font-weight: 800;
  opacity: 0.7;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  font-weight: 800;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth {
  color: gray !important;
  opacity: 0.8 !important;
}
