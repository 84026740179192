.container {
  flex: 1;
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global {
    .ant-card-head {
      border-bottom: 1px solid #636262;
    }
    .ant-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      .ant-row:first-child {
        flex: 1;
      }
    }
  }
}
.row {
}

.title {
  text-align: left;
  font: normal normal bold 16px/24px Sarabun;
  letter-spacing: 0px;
  color: #1f313d;
  opacity: 1;
}

.description {
  text-align: left;
  font: normal normal normal 14px/17px Sarabun;
  letter-spacing: 0px;
  color: #1f313d;
}

.boldDescription {
  text-align: left;
  font: normal normal bold 14px/17px Sarabun;
  letter-spacing: 0px;
  color: #1f313d;
}
