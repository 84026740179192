@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import 'reset';
@import './table.module.scss';

* {
  font-family: 'Sarabun', sans-serif;
}
.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  //background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  font-size: 1rem;
}
.ant-pagination-item {
  border-radius: 4px;
  border: 1px solid #ececec;
  color: #faac18;
  &:hover {
    border: 1px solid #ececec;
    opacity: 0.7;
    a {
      color: #faac18;
    }
  }
}

.disable-whole-section {
  opacity: 0.7;
  pointer-events: none !important;
}

.ant-pagination-item-active {
  background-color: #faac18 !important;
  color: #ffffff !important;
  border: none !important;
  a {
    color: #fff !important;
  }
}

.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #ececec !important;
  border-color: #ececec !important;
}
.ant-table-wrapper {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    //background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e8c199;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-table-cell {
    &:before {
      display: none;
    }
  }
}
.ant-table-body {
  border: 1px solid #ececec;
  border-top: none;

  .ant-table-cell {
    &:before {
      display: none;
    }

    button[class*='buton_secondary'] {
      background-color: #e8c199 !important;
    }
  }
}
.ant-table-column-sorters {
  justify-content: left !important;
}
.ant-table-column-title {
  flex: none !important;
}

.chartColl {
}

@media print {
  div {
    float: none !important;
    //position: static !important;
    //box-sizing: content-box !important;
    display: block !important;
  }
}
@media print {
  .chartColl {
    //margin-top: 50px;
    //margin-bottom: 50px;
    //min-height: 450px;
    //page-break-after: always;
    page-break-before: always;
  }
}

//.ant-picker-cell{
//  width: 28px;
//  //padding: 0px;
//  .ant-picker-cell-inner{
//    margin: auto;
//    width: 28px!important;
//    height: 28px!important;
//    margin-top: 1px;
//  //height: 12px !important;
//
//}
//}
//.ant-picker-cell-selected{
//  background-color: #002637;
//  border-radius: 100% !important;
//  .ant-picker-cell-inner{
//    background-color: #002637 !important;
//    border-radius: 100% !important;
//}
//}

.ant-tabs-tab {
  min-width: 100px;
}

//.ant-tabs-tab-btn{
//  margin: auto;
//}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #777777 !important;
  font-weight: 500;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #faac18 !important;
  font-weight: 500;
}
//.ant-tabs-ink-bar {
//  position: absolute;
//  background: #002637;
//  pointer-events: none;
//}

.ant-radio-checked .ant-radio-inner {
  border-color: #faac18 !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #faac18;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #faac18;
}
.custom-divider {
  margin-top: 8px;
  margin-bottom: 8px;
}

.standard-select {
  width: 142px;
}

.ant-slider-track {
  background-color: #faac18 !important;
}

.ant-slider-handle {
  border-color: #faac18 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #faac18 !important;
  border-color: #faac18 !important;
}

.ant-modal-footer {
  .ant-btn-primary {
    border: none;
    background-color: #faac18;
    &:active,
    &:hover,
    &:focus {
      background-color: #faac18;
      color: #ffffff;
    }
    &:disabled,
    &:disabled:hover {
      background-color: #faac18;
      color: #ffffff;
      opacity: 0.7;
    }
  }
}

.ant-input:focus,
.ant-input-focused {
  border-color: #faac18;
  box-shadow: 0 0 0 2px rgba(217, 50, 46, 0.2);
  border-right-width: 1px;
  outline: 0;
}

.ant-input:hover {
  border-color: #faac18;
  border-right-width: 1px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #faac18;
  border-right-width: 1px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #faac18;
  box-shadow: 0 0 0 2px rgba(217, 50, 46, 0.2);
}

.ant-btn:hover,
.ant-btn:focus {
  color: #faac18;
  border-color: #faac18;
  background: #fff;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #faac18;
  pointer-events: none;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #faac18;
}

.mb8 {
  margin-bottom: 8px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #faac18;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #faac18;
}

div.ant-image-preview-wrap
  > div
  > div.ant-image-preview-content
  > div
  > ul
  > li:nth-child(5) {
  display: none;
}

div.ant-image-preview-wrap
  > div
  > div.ant-image-preview-content
  > div
  > ul
  > li:nth-child(4) {
  display: none;
}
