.inputWidth{
  width: 100px;
}

.iconContainer{
  font-size: 80px;
  color: #D9322E;
  border: 2px solid #D9322E;
  margin-bottom: 16px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: .8;
}