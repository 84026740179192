.footer{
  //background: #D9322E;
  background: #FFFFFF;
  &_bottom{
    margin-top: 24px;
  }
}

.titles{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  text-align: left;
  p{
    margin: 0;
  }
  .link{
      color: #000000 !important;
      &:hover{
        color: #000000;
        opacity: 0.7;
      }

  }
}
.textWrapper{
  display: flex;
  justify-content: center;
  svg{
    //margin-top: 2px;
    margin-right: 4px;
    min-width: 16px;
    min-height: 16px;
  }
}
.links{
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  color: #000000;
  a{
    color: #000000;
    &:hover{
      opacity: 0.7;
      color: #000000;
    }
  }
}
