.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #1C1C20;
}

.subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #1C1C20;
  opacity: 0.8;
}

.priceColored {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #D9322E;
}

.totalPrice {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #1C1C20;
}

.mainPrice {
  //position: absolute;
  //left: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  /* #1C1C20 */

  color: #1C1C20;

}

.button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  //color: #1C1C20;
  min-width: 163px;
  min-height: 48px;
  border-radius: 6px;
}

.successButton {
  background: #D9322E !important;
  border-color: #D9322E !important;
  color: #FFFFFF;
}
.cancelButton {
  border-color: #1C1C20 !important;
  color: #1C1C20;

  &:hover {
    color: #1C1C20 !important;
  }
  &:focus {
    color: #1C1C20 !important;
  }
}