.container{
  flex: 1;
  height: 100%;
}

.card{
  display: flex;
  flex-direction: column;
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global{
    .ant-card-head{
      border-bottom: 1px solid #636262;
    }
    .ant-card-body{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.row{
}