.cashier-order-card {
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  display: flex;
  flex-direction: row;
  flex: 1;
  .cashier-card-body {
    margin-left: 32px;
    flex: 1;
    display: flex;
    .cashier-card-description {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      .card-order-number {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */

        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #282828;
      }
      .card-order-title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px !important;
        line-height: 120%;
        color: #282828;
      }
      .card-order-details {
        font-size: 12px !important;
        line-height: 150%;
        /* identical to box height, or 24px */
        color: #282828;
        span {
          margin-right: 24px;
        }
      }
      .card-order-type {
        justify-self: flex-end;
        display: flex;
        .order-type-placeholder {
          display: flex;
          align-items: center;
          padding-left: 16px;
          width: 206px;
          height: 44px;
          border: 1px solid #d6d6d6;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 150%;
          /* identical to box height, or 24px */

          color: #282828;
        }
        .ant-select {
          .ant-select-selector {
            padding: 0 16px;
            border-color: #282828;
            height: 44px;
            .ant-select-selection-item {
              display: flex;
              align-items: center;
              font-size: 16px;
              line-height: 150%;
              /* identical to box height, or 24px */

              color: #282828;
            }
          }
          .ant-select-arrow {
            color: #282828;
          }
        }
        .type-color {
          margin-right: 20px;
        }
        .type-label {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          color: #282828;
        }
      }
    }
    .cashier-card-actions {
      display: flex;
      align-items: flex-end;
      .order-modify-btn {
        &:hover {
          background: #000000 !important;
          border-color: #000000 !important   ;
        }
        height: 44px;
        background-color: #000000;
        border-color: #000000;
        border: 1px solid #000000;
        width: 180px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        letter-spacing: 0.01em;
        text-transform: uppercase;

        color: #ffffff;
        &.order-save-btn {
          &:hover {
            background: #1db65e !important;
            border-color: #1db65e !important   ;
          }
          background-color: #1db65e;
          border-color: #1db65e;
          border: 1px solid #1db65e;
        }
      }
      .order-remove-btn {
        margin-left: 12px;
        width: 239px;
        height: 44px;
        border: 1px solid #ed6363;
        box-sizing: border-box;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        /* or 19px */

        letter-spacing: 0.01em;
        text-transform: uppercase;

        /* #ED6363 */

        color: #ed6363;
        &:disabled {
          color: #ced5e2;
          border-color: #ced5e2;
          background-color: #fff;
        }
      }
    }
  }
}

.order_info_block {
  flex: 1;
  min-width: 200px;
  overflow-y: auto;
  //max-height: 230px;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid silver;

  &_line {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 10px;
    row-gap: 15px;
    border-left: unset;
    padding-left: 0;
    margin-left: 0;
  }
}
