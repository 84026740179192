.container {
  flex: 1;
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global {
    .ant-card-head {
      border-bottom: 1px solid #636262;
    }
    .ant-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      .ant-row:last-child {
        flex: 1;
        align-items: end;
      }
    }
  }
}
.row {
}

.inputContainer {
  position: relative;
}

.placeholderText {
  position: absolute;
  left: 10px;
  top: 0px;
  z-index: 1;

  font: normal normal medium 10px/34px Lato;
  letter-spacing: -0.17px;
  opacity: 1;

  color: #efb042;
  pointer-events: none;
  min-width: 250px;
}

.input {
  height: 44px;
  color: #1f313d;
  min-width: 260px;

  font: normal normal bold 16px/34px Lato;
  background: #ffffff 0% 0% no-repeat padding-box;
  letter-spacing: -0.27px;
  opacity: 1;
  border: 1px solid #efb042;
  border-radius: 6px;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.45);
}

.buttonFirst {
  width: 155px;
  height: 44px;

  padding: 12px 18px;

  text-align: center;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: -0.23px;
  color: #faac18;
  opacity: 1;

  background: #4b4b4b 0% 0% no-repeat padding-box;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.buttonFirst:hover {
  opacity: 0.8;
}

.buttonSecond {
  width: 155px;
  height: 44px;

  padding: 12px 18px;

  text-align: center;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: -0.23px;
  color: #ffffff;
  opacity: 1;

  background: #faac18 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
