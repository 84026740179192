.container{
  padding: 30px 20px ;
  flex: 1;
  height: 100%;
}

.card{
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
}