.homeIcon {
  margin-right: 8px;

  svg {
    font-size: 20px;
  }
}
.logo {
  margin-top: 16px;
  height: 90px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.sideBar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 244px;
  background-color: #faac18;
}

.menu {
  background-color: #faac18;
  width: calc(100%);
  border-right: none !important;
  :global {
    .ant-menu-item {
      white-space: normal;
      height: auto !important;
      line-height: 20px !important;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
    }
    .ant-menu-title-content {
      inline-size: 150px;
      overflow-wrap: break-word;
      white-space: normal;
      height: auto;
    }
    .ant-menu-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      height: 53px;
      padding-left: 30px !important;
      width: calc(100%);
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
      }
      &:after {
        display: none;
      }
      &:hover {
        color: #fff;
      }
    }
    .ant-menu-item-selected {
      color: #fff;
      background-color: rgba(47, 41, 41, 0.35) !important;
    }
  }
}

.topSelectWrapper {
  padding-left: 16px;
  padding-right: 22px;
  margin-bottom: 28px;
}

.topSelect {
  width: 100%;
  height: 65px;
  background-color: #001925;
  :global {
    .ant-select-selector {
      svg {
        display: none;
      }
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      height: 65px !important;
      padding: 0 16px !important;
      background-color: #001925 !important;
      border: none;
      display: flex;
      align-items: center;
    }
    .ant-select-arrow {
      color: #fff;
      font-size: 14px;
      right: 21px;
    }
  }
  &_dropdown {
    background-color: #001925;
    color: red;
    :global {
      .ant-select-item {
        height: 45px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #568ca4;
        &:hover {
          background-color: #001925;
          opacity: 0.7;
        }
        .ant-select-item-option-content {
          display: flex;
          align-items: center;
          svg {
            margin-right: 8px;
          }
        }
      }
      .ant-select-item-option-selected {
        background-color: rgba(4, 101, 146, 0.37);
        &:hover {
          background-color: rgba(4, 101, 146, 0.37);
          opacity: 1;
        }
      }
      .ant-select-item-option-active {
        background-color: #001925;
      }
    }
  }
}

.link {
  a {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      margin-left: 20px;
      font: normal normal bold 14px/18px Sarabun;
      letter-spacing: 0px;
      color: #ffffff;
    }
    &:hover {
      color: inherit;
    }
  }
}

.linkProfile {
}
.row {
  height: calc(100% - 100px - 16px);
}
.rowBottom {
  height: 100%;
}
