.wrapper{
  position: relative;
  width: 100%;
  height: auto;
  input {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: -0.27px;
    color: #636262;
    outline: none;
    &::-webkit-input-placeholder { /* Edge */
      opacity: .6;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: .6;
    }

    &::placeholder {
      opacity: .6;
    }
  }
}