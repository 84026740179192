.header {
  z-index: 1;
  background-color: #fff;
  position: fixed;
  display: flex;
  width: calc(100% - 244px);
  min-height: 52px;
  height: auto;
  //z-index: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 24px;
  justify-content: space-between;
  padding-left: 15px;

  &_full {
    width: 100%;
  }
}

.headerRow {
  width: 100%;
  display: flex;
}

.logo {
  height: 49.35px;
  justify-self: flex-start;
}

.leftSide,
.rightSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftSide > [class*='ant-btn-primary-white'] {
  border: 0;
  font-size: 12px;
  line-height: 1.2;
  color: #777;
}

.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #faac18;
  margin-left: 10px;
  svg {
    margin-left: 12px;
  }
  &:hover {
    color: #faac18;
  }
}

.changeShopBtn {
  margin-right: 8px;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.steps {
  margin-top: 16px;
  margin-left: -50px;
  :global {
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: #faac18;
    }
    .ant-steps-item-finish
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      background: #faac18;
    }
    .ant-steps-item-process
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      background: #faac18;
    }
  }
}
