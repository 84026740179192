.card {
  border: 1px #808080 dashed;
  border-radius: 8px;
  min-height: 100%;
  width: 100%;
  :global {
    .ant-card-head {
      border-bottom: 1px solid #636262;
    }

    .ant-btn {
      white-space: pre-wrap;
    }
  }
}

.cardSection {
  width: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #636262;
}

.circle_count_red {
  position: absolute;
  background: red;
  color: white;
  border-radius: 100%;
  z-index: 100;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  left: 102%;
  bottom: 1%;
}

.circle_parent_element {
  position: relative;
}
