.modalBody{
  display: flex;
  flex-direction: column;
}

.footerPrice{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #282828;
}

.footer {
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderFooterLeftSide{
  display: flex;
  align-items: center;
}
.orderFooterLeftSideTitles{
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}
.footerTitle{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #282828;
}
.footerPrice{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #282828;
}
.infoIcon{
  color: #1C1C20;
  height: 56px;
  width: 56px;
  font-size: 56px;
}
.orderSubmitBtn {
  &:hover {
    background: #000000 !important;
    border-color: #000000 !important   ;
  }
  width: 320px;
  height: 56px;
  background-color: #000000;
  border: 1px solid #000000;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;
}
.orderInfoBlock {
  flex: 1;
  min-width: 200px;
  overflow-y: auto;
  //max-height: 230px;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid silver;

  &_line {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    column-gap: 10px;
    row-gap: 15px;
    border-left: unset;
    padding-left: 0;
    margin-left: 0;
  }
}
