.container{
  display: inline-block;
  border: 0.5px solid #636262;
  border-radius: 6px;
  position: relative;
  padding: 5px 6px;
  :global{
    .ant-picker{
      padding: 0px;
      input{
        font: normal normal 600 16px/16px Sarabun;
        letter-spacing: -0.27px;
        color: #636262;
      }
    }
    .ant-picker-input{
      display: flex;
      align-items: flex-end;
    }
  }
}

.label{
  font: normal normal medium 10px/34px Sarabun;
  letter-spacing: -0.17px;
  color: #636262;
  position: absolute;
  top: 0;
}