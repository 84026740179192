.content{
  flex: 1;
  //min-height: 100%;
  margin-top: 52px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  & > div:first-child{
    flex: 1;
  }
}
.contentWithSteps {
  margin-top: 98px;
}

.contentHide{
  margin-top: 0;
}

.siteLayout{
  display: flex;
  flex-direction: column;
  margin-left: 244px;
  height: 100vh;
}

.siteLayoutHide{
  margin-left: 0;
}

.sitePendingRequest {
  //opacity: 0.6;
  //pointer-events: none;
  //user-select: none;
}
.container{

  //min-height: 100%;
  //height: auto;
}
.spin{
  width: 100%;
}