.myTable {
  :global {
    .ant-table-thead {
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      border-radius: 24px 4px 0px 0px;
      th {
        background-color: #faac18 !important;
        color: #ffffff !important;
        border-right: none !important;
      }
      th:first-child {
        border-top-left-radius: 4px !important;
      }
      th:last-child {
        border-top-right-radius: 4px !important;
      }
    }
    .ant-table,
    .ant-table-content,
    .ant-table-container {
      border-radius: 8px;
    }
  }
}
