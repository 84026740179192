.selectDelivery {
  width: 200px;
}

.iconContainer {
  color: #d9322e;
  border: 2px solid #d9322e;
  margin-bottom: 16px;
  width: 220px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.8;
}

.calendar_modal {
  display: flex;
  align-items: center;
  gap: 70px;
}

.white_button_modal {
  background: none !important;
  color: black !important;
  border: 1px solid #d9d9d9;
  &:hover {
    border-color: #aaa !important;
    color: #aaa !important;
  }
}
