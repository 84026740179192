.loginPageContainer{
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F7F7F7;
  &_content{
    flex: 1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
