.container{
  display: inline-flex;
  align-items: flex-end;
  border: 0.5px solid #636262;
  border-radius: 6px;
  position: relative;
  padding: 5px 6px;
  min-height: 44px;
  width: 352px;
  :global{
    input, textarea{
      padding-top: 12px;
      font: normal normal 600 16px/16px Sarabun;
      letter-spacing: -0.27px;
      color: #636262;
      box-shadow: none!important;
    }
  }
}

.label{
  font: normal normal medium 10px/34px Sarabun;
  letter-spacing: -0.17px;
  color: #636262;
  position: absolute;
  top: 0;
  left: 15px;
}