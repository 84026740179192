.google-places-autocomplete__suggestions-container {
  border: 1px solid #d9d9d9;
  position: absolute;
  border-radius: 6px;
  background-color: #ffffff;
  z-index: 1000;
}

.google-places-autocomplete__suggestion {
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  transition: background-color 100ms linear;
  &:hover {
    background-color: rgba(61, 191, 178, .6);
    color: #ffffff;
  }
}
.google-places-autocomplete{
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.google-places-autocomplete__input{
  border: none;
}