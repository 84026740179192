.wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 8px;
  height: 34px;
  :global{
    .ant-select-selection-item{
      font-weight: bold;
    }
  }
  input{
    box-shadow: none!important;
  }
}
.title{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #002637;

  opacity: 0.5;
}

.closeIcon{
  cursor: pointer;
  &:hover{
    opacity: 0.7;
  }
}