.card{
  border: 1px #D5D5D5 solid;
  height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cardBig{
  height: 260px;
}
.count{
  font-size: 60px;
  margin-bottom: 0!important;
  color: #707070;
  font-weight: bold;

}

.smallCount{
  font-size: 30px;
}

.text{
  font-size: 20px;
  color: #707070;
  text-align: center;
}