.menu{
  width: 274px;
  :global{
    .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected{
      color: #000;
      background-color: #fff;
    }
  }
}

.checkIcon{
  color: #E8C199;
}

.checkbox{
  margin: 0 8px;
}